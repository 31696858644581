import { Box, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import copy from "copy-to-clipboard";
import { graphql, withPrefix } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  ImArrowLeft2,
  ImFacebook,
  ImLink,
  ImLinkedin,
  ImTwitter,
} from "react-icons/im";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Content, { HTMLContent } from "../components/Content";
import HeroComponent from "../components/HeroComponent";
import Layout from "../components/Layout";

import "./styles/blog-post.scss";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  date,
  location,
  featuredImage,
}) => {
  const PostContent = contentComponent || Content;
  const url = location.href || "";
  const toast = useToast();
  const toastId = "copyToast";

  const onCopyLink = () => {
    if (!toast.isActive(toastId)) {
      copy(url);
      toast({
        id: "copyToast",
        title: "Link Copied!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    function shareScroll() {
      const currentScrollPos = window.pageYOffset;

      if (document.getElementById("share-comp")) {
        if (currentScrollPos < 420) {
          document.getElementById("share-comp").style.right = "-80px";
        } else {
          document.getElementById("share-comp").style.right = "0";
        }
      }
    }

    window.addEventListener("scroll", shareScroll);

    return () => window.removeEventListener("scroll", shareScroll);
  }, []);

  return (
    <section>
      {helmet || ""}
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        flexDir="column"
        justifyContent="flex-end"
        py={{ base: "40px", md: "6rem" }}
        px={{ base: 0, md: "6rem" }}
        minH="85vh"
        maxH="672px"
      >
        <Box className="share-comp" id="share-comp">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FacebookShareButton url={url}>
              <Icon
                color="white"
                fontSize="24px"
                as={ImFacebook}
                cursor="pointer"
                _hover={{
                  color: "primary.500",
                }}
              />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <Icon
                mt={6}
                color="white"
                fontSize="24px"
                as={ImTwitter}
                cursor="pointer"
                _hover={{
                  color: "primary.500",
                }}
              />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
              <Icon
                mt={6}
                color="white"
                fontSize="24px"
                as={ImLinkedin}
                cursor="pointer"
                _hover={{
                  color: "primary.500",
                }}
              />
            </LinkedinShareButton>
            <Icon
              mt={6}
              color="white"
              fontSize="24px"
              mb="4px"
              as={ImLink}
              onClick={onCopyLink}
              cursor="pointer"
              _hover={{
                color: "primary.500",
              }}
            />
          </Flex>
        </Box>
        <Box position="relative" zIndex="5" className="wrapper-lg">
          <Icon
            as={ImArrowLeft2}
            fontSize="32px"
            color="#FFFFFF"
            mb={{ base: 6, md: 8, lg: 12 }}
            onClick={() => navigate("/insights")}
            _hover={{
              color: "primary.500",
            }}
            cursor="pointer"
          />
          <Box>
            <Text
              fontSize={{ base: "56px", md: "64px", lg: "80px" }}
              lineHeight={{ base: "64px", md: "72px", lg: "88px" }}
              fontFamily="Antonio"
              color="primary.500"
              width={{ base: "100%", md: "80%", lg: "90%" }}
              as="h1"
              mb={{ base: 6, md: 8, lg: 12 }}
            >
              {title}
            </Text>
            <Flex
              flexDir={{ base: "column", md: "row" }}
              justifyContent="space-between"
              alignItems={{ base: "flex-start", md: "center" }}
            >
              {/* <Text
                as="p"
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight={{ base: "28px", md: "32px" }}
                color="#FFFFFF"
                mb={{ base: 4, md: 0 }}
              >
                {date}
              </Text> */}
              <Flex>
                <FacebookShareButton url={url}>
                  <Icon
                    color="white"
                    fontSize="24px"
                    mb="4px"
                    as={ImFacebook}
                    cursor="pointer"
                    _hover={{
                      color: "primary.500",
                    }}
                  />
                </FacebookShareButton>
                <LinkedinShareButton url={url}>
                  <Icon
                    ml={6}
                    color="white"
                    fontSize="24px"
                    as={ImLinkedin}
                    cursor="pointer"
                    mb="4px"
                    _hover={{
                      color: "primary.500",
                    }}
                  />
                </LinkedinShareButton>
                <TwitterShareButton url={url}>
                  <Icon
                    ml={6}
                    color="white"
                    fontSize="24px"
                    mb="4px"
                    as={ImTwitter}
                    cursor="pointer"
                    _hover={{
                      color: "primary.500",
                    }}
                  />
                </TwitterShareButton>
                <Icon
                  ml={6}
                  color="white"
                  fontSize="23px"
                  mb="4px"
                  as={ImLink}
                  onClick={onCopyLink}
                  cursor="pointer"
                  _hover={{
                    color: "primary.500",
                  }}
                />
              </Flex>
            </Flex>
          </Box>
        </Box>

        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.6"
            backgroundColor="#000000"
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage={`url(${featuredImage})`}
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box className="wrapper-lg" my="4rem">
        {/* <p className="sc-description">{description}</p> */}
        <PostContent
          className="blog-content sc-description"
          content={content}
        />
      </Box>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <BlogPostTemplate
        location={location}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              property="og:title"
              name="og:title"
              content={`${post.frontmatter.title}`}
            />
            <meta
              property="og:description"
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={`${withPrefix("")}${
                post.frontmatter.featuredimage
                  ? post.frontmatter.featuredimage.childImageSharp.fluid.src
                  : ""
              }`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        featuredImage={
          post.frontmatter.featuredimage
            ? post.frontmatter.featuredimage.childImageSharp.fluid.src
            : ""
        }
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
