import { Box, Grid, Text } from "@chakra-ui/react";
import { graphql, navigate, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import "./styles/blog-roll.scss";

const NewsRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Grid
      gridTemplateColumns={{ base: "none", md: "repeat(3, 1fr)" }}
      gridTemplateRows={{ base: "auto", md: "none" }}
      gridColumnGap={{ base: 0, md: 8 }}
      gridRowGap={{ base: "40px", md: 12 }}
    >
      {posts &&
        posts.map(({ node: post }) => (
          <Box
            display="flex"
            flexDir="column"
            key={post.id}
            onClick={() => navigate(post.fields.slug)}
            cursor="pointer"
          >
            <Box height={240}>
              <PreviewCompatibleImage
                className="blog-roll__image"
                imageInfo={{
                  image: post.frontmatter.featuredimage,
                  alt: `${post.frontmatter.title}`,
                }}
              />
            </Box>
            <Box my="8px" p="16px" bg="#C3F9E0">
              <Text className="ae-blog-title" mb={2}>
                {post.frontmatter.title}
              </Text>
              {/* <Text fontSize="14px" lineHeight="20px">
                {post.frontmatter.date}
              </Text> */}
            </Box>
            <Text className="ae-desc-one">{post.excerpt}</Text>
          </Box>
        ))}
    </Grid>
  );
};

NewsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query NewsRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: {
            frontmatter: {
              templateKey: { eq: "news-post" }
              hidden: { eq: false }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 320, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewsRoll data={data} count={count} />}
  />
);
