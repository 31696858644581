import { Box, Grid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import InView from "react-intersection-observer";
import YouTube from "react-youtube";
import ContactComponent from "../../components/ContactComponent";
import HeroComponent from "../../components/HeroComponent";
import Layout from "../../components/Layout";
import useHover from "../../hooks/useHover";

const IMAGES = [
  {
    src: "/img/ae-exhibit-one.png",
    thumbnail: "/img/ae-exhibit-one.png",
    alt: "aevias virtual example one",
  },
  {
    src: "/img/ae-exhibit-two.png",
    thumbnail: "/img/ae-exhibit-two.png",
    alt: "aevias virtual example two",
  },
  {
    src: "/img/ae-exhibit-three.png",
    thumbnail: "/img/ae-exhibit-three.png",
    alt: "aevias virtual example three",
  },
];

const ExhibitManagementPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  useEffect(() => {
    if (isHeroTextHovered) {
      console.log("hovered");
    }
  }, [isHeroTextHovered]);

  const renderSolutions = () => {
    const solutions = [
      {
        icon: "/img/meeting/ae-content.svg",
        title: "Booth design and mockup",
      },
      {
        icon: "/img/meeting/ae-budget.svg",
        title: "Budget development and management",
      },
      {
        icon: "/img/meeting/ae-technical.svg",
        title: "Vendor management",
      },
      {
        icon: "/img/meeting/ae-measurement.svg",
        title: "Measurement for success",
      },
      {
        icon: "/img/meeting/ae-strategy-design.svg",
        title: "Event management",
      },
      {
        icon: "/img/meeting/ae-speaker.svg",
        title: "Sourcing and contracting",
      },
      {
        icon: "/img/meeting/ae-speaker.svg",
        title: "Onsite staffing",
      },
      {
        icon: "/img/meeting/ae-content.svg",
        title: "And so much more",
      },
    ];

    return solutions.map((solution, index) => (
      <Grid
        key={index}
        gridTemplateColumns={{
          base: "24px 1fr",
          md: "32px 1fr",
        }}
        columnGap="24px"
        alignItems="center"
        marginBottom="24px"
      >
        {solution.icon ? (
          <img src={solution.icon} alt={solution.title} />
        ) : (
          <Box width="32px" height="32px" />
        )}
        <Text
          fontWeight={500}
          fontSize={{ base: "20px", md: "24px" }}
          lineHeight={{ base: "28px", md: "32px" }}
          color="secondary.500"
        >
          {solution.title}
        </Text>
      </Grid>
    ));
  };

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: 0, md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
            lineHeight={{ base: "80px", md: "104px", lg: "128px" }}
            marginBottom={{ base: 6, md: 0 }}
          >
            <motion.span
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Exhibit Management
            </motion.span>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
          fontSize={{ base: "18px", md: "24px", lg: "32px" }}
          lineHeight={{ base: "26px", md: "32px", lg: "40px" }}
          px={{ base: "24px", md: 0 }}
          mt="1rem"
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, delay: 0.6, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            Drive engagement and impact
            <br />
            through memorable exhibit experiences.
          </motion.span>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/aevias-hero-one.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box ref={scrollHere} as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              my={{ base: "40px", md: "8rem" }}
              display="flex"
              alignItems={{ base: "flex-start", md: "center" }}
              pl={{ base: "24px", md: "6rem" }}
            >
              <Box ref={ref}>
                {inView && (
                  <Grid
                    gridTemplateColumns={{ base: "none", md: "0.75fr 1fr" }}
                    gridTemplateRows={{ base: "auto auto", md: "none" }}
                    gridColumnGap={{ base: 0, md: "160px" }}
                    gridRowGap={{ base: "40px", md: 0 }}
                    alignItems="center"
                  >
                    <Box>
                      <Text as="h2" className="ae-title-two" mb="6">
                        Our expert team collaborates closely with you to
                        understand your brand's essence and objectives, ensuring
                        every detail reflects your unique identity.
                      </Text>
                      <Text as="p" className="ae-desc-one">
                        Whether it's crafting captivating booth designs,
                        coordinating logistics for seamless installations, or
                        orchestrating engaging interactions with your audience,
                        we're dedicated to elevating your presence and
                        generating business growth.
                      </Text>
                    </Box>
                    <Box
                      position="relative"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src="/img/ae-exhibit-mangamenet-section.jpg"
                        alt="Aevias virtual events experiences"
                      />
                      <Box
                        zIndex="-1"
                        background="primary.500"
                        width="calc(100% + 40px)"
                        height="100%"
                        position="absolute"
                        right="0"
                        bottom="-40px"
                      />
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box as="section">
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <Box
              as="section"
              mt="4rem"
              mb={{ base: 12, md: "8rem" }}
              display="flex"
              alignItems="center"
            >
              <Box ref={ref} className="wrapper-xl">
                <Text
                  as="h2"
                  color="secondary.500"
                  fontSize={{ base: "56px", md: "64px", lg: "80px" }}
                  lineHeight={{ base: "64px", md: "72px", lg: "88px" }}
                  fontWeight="300"
                  letterSpacing="-5px"
                  mb="4rem"
                >
                  How Aevias Can Help
                </Text>
                {inView && (
                  <Box display="flex">
                    <Box
                      display={{ base: "flex", md: "grid" }}
                      flexDir="column"
                      gridTemplateColumns="1fr 1fr"
                      gridColumnGap="160px"
                    >
                      {renderSolutions()}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </InView>
      </Box>

      <Box
        mt={{ base: "100px", md: "60px", lg: "120px" }}
        backgroundColor="primary.500"
        as="section"
        position="relative"
        py={{ base: "60px", md: "80px", lg: "120px" }}
        pb={{ base: "1rem", md: "4rem", lg: "4rem" }}
      >
        <Box className="wrapper-xl">
          <Text
            top={{ base: "-5.5rem", md: "-6rem", lg: "-9rem" }}
            position="absolute"
            whiteSpace="pre-wrap"
            as="h2"
            color="secondary.500"
            letterSpacing="-5px"
            fontSize={{ base: "48px", md: "64px", lg: "80px" }}
            lineHeight={{ base: "56px", md: "72px", lg: "88px" }}
          >
            Here's a taste of what
            <br />
            we can do
          </Text>
          <Box>
            <Box flexDir="column">
              <Box
                display="flex"
                flexDir={{ base: "column", lg: "row" }}
                height="420px"
                style={{ gap: "40px", height: "100%" }}
              >
                <img
                  src={IMAGES[0].src}
                  alt={IMAGES[0].alt}
                  style={{ height: "100%" }}
                />
                <img
                  src={IMAGES[2].src}
                  alt={IMAGES[2].alt}
                  style={{ width: "100%", objectFit: "auto" }}
                />
              </Box>
              <Box mt="40px">
                <img
                  className="ae-image"
                  src={IMAGES[1].src}
                  alt={IMAGES[1].alt}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <ContactComponent />
      </Box>
    </Layout>
  );
};

export default ExhibitManagementPage;
